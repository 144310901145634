<template>
  <div
    class="row item-details-page"
  >
    <div class="col-md-8">
      <div class="input-wrap">
        <v-treeview
          open-all
          dense
          selectable
          open-on-click
          selected-color="teal"
          :items="objects"
        >
          <template v-slot:prepend="{ open }">
            <v-icon>
              {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
            </v-icon>
          </template>
        </v-treeview>
      </div>
    </div>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'

  export default {
    mixins: [validationMixin],
    props: {
      object: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        objects: [
          {
            id: 1,
            name: 'Category 1',
            children: [
              { id: 1.1, name: 'Subcategory 1' },
              { id: 1.2, name: 'Subcategory 2' },
              { id: 1.3, name: 'Subcategory 3' },
            ],
          },
          {
            id: 2,
            name: 'Category 2',
            children: [
              { id: 2.2, name: 'Subcategory 1' },
              { id: 2.3, name: 'Subcategory 2' },
              { id: 2.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 3,
            name: 'Category 3',
            children: [
              { id: 3.2, name: 'Subcategory 1' },
              { id: 3.3, name: 'Subcategory 2' },
              { id: 3.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 4,
            name: 'Category 4',
            children: [
              { id: 4.2, name: 'Subcategory 1' },
              { id: 4.3, name: 'Subcategory 2' },
              { id: 4.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 5,
            name: 'Category 5',
            children: [
              { id: 5.2, name: 'Subcategory 1' },
              { id: 5.3, name: 'Subcategory 2' },
              { id: 5.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 6,
            name: 'Category 6',
            children: [
              { id: 6.2, name: 'Subcategory 1' },
              { id: 6.3, name: 'Subcategory 2' },
              { id: 6.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 7,
            name: 'Category 7',
            children: [
              { id: 7.2, name: 'Subcategory 1' },
              { id: 7.3, name: 'Subcategory 2' },
              { id: 7.4, name: 'Subcategory 3' },
            ],
          },
          {
            id: 8,
            name: 'Category 8',
            children: [
              { id: 8.2, name: 'Subcategory 1' },
              { id: 8.3, name: 'Subcategory 2' },
              { id: 8.4, name: 'Subcategory 3' },
            ],
          },
        ],
      }
    },
    created () {
      if (this.object) {
        this.objectStreet = this.object.objectStreet || ''
      }
    },
  }
</script>
